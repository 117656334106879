/* src/ReferralForm.css */
.referral-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-title {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #333333; /* Form Title Color */
}

.form-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.form-input:focus {
  border-color: #1E90FF; /* Input Border Color */
  outline: none;
}

.submit-button {
  background-color: #32CD32; /* Submit Button Color */
  color: #FFFFFF; /* Secondary Color */
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #228B22; /* Submit Button Hover Color */
}
