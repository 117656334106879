/* src/App.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f2f5;
}

.app-container {
  text-align: center;
  padding: 50px;
  background-color: #1E90FF; /* Primary Color */
  border: 2px solid #1E90FF; /* Primary Color */
  border-radius: 10px;
  margin: 50px auto;
  max-width: 800px;
}

.hero-section {
  color: #FFFFFF; /* Secondary Color */
  padding: 100px 20px;
}

.title {
  font-size: 3rem;
  margin-bottom: 20px;
}

.refer-button {
  background-color: #FF6347; /* Button Color */
  color: #FFFFFF; /* Secondary Color */
  border: none;
  padding: 15px 30px;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.refer-button:hover {
  background-color: #FF4500; /* Button Hover Color */
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #F5F5F5; /* Modal Background Color */
  padding: 20px;
  border-radius: 10px;
  position: relative;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #1E90FF; /* Close Button Color */
}

.close-button:hover {
  color: #0056b3; /* Darker shade for hover */
}
